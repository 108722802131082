import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  correctAnswerValidator,
  answerLengthValidator,
  quizModulesValidator,
  answerDesirabilityValidator,
  imageOrQuestionValidator,
  maxNumberOfAnswersCandidateCanChooseValidator
} from 'src/app/validators/quiz-question.validator';
import { gsmCharsetValidationRegex } from 'src/app/resources/regex-validators';
import { QUIZ_MODULES_ARRAY } from 'src/app/resources/quiz-modules';
import { blankCharacterValidation } from 'src/app/validators/blank-character.validator';
import { correctActivDate, correctActivationDateTime } from 'src/app/validators/correct-date.validator';
import { Answer, InhouseQuiz, Question, Quiz, TypeOfSkillsQuestion, VideoQuestion } from 'src/app/model/job.interface';
import { maxVideoMainMessageLength, maxVideoMessageLength } from './video-intro-content';
import { SetupService } from '../services/setup.service';
import { INHOUSE_QUESTION_CONTENT } from './inhouse-question-content';
import { TranslationKeys } from '../model/translation-object.interface';
import { Trigger } from '../model/trigger.interface';
import { checkIsStreetIsNumber, compareHighestAndLowestWeeklyWorkingHoursValidator } from '../validators/jobnet.validator';

const fb = new FormBuilder();
const smsValidationPattern = gsmCharsetValidationRegex;

export const maxIntroMessageLength = 100;
export const maxIntroMainMessageLength = 300;
export const maxReminderSmsLength = 133;
export const costCenterMaxLength = 65500;
export const tagMaxLength = 255;
export const finnMaxNumberOfRemotes = 2;
export const finnMaxNumberOfLanguages = 2;
export const finnMaxNumberOfIndustries = 3;
export const finnMaxNumberOfSpecialization = 3;
export const MAX_NUMBER_OF_VACANCIES = 99;
export const MAX_NUMBER_OF_WEEKLY_WORKING_HOURS = 36;
export const MIN_NUMBER_OF_WEEKLY_WORKING_HOURS = 1;
export const POSTAL_NUMBER_LENGTH = 4;

export function initializeForm(): FormGroup {
  return fb.group({
    jobInfo: fb.group(
      {
        jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
        jobCategory: fb.control(null, [Validators.required]),
        branch: fb.control(null, [Validators.required]),
        jobLocation: fb.control(null, [Validators.required]),
        jobWorkLocation: fb.control(null),
        typeOfEmployment: fb.control(null, [Validators.required]),
        typeOfEmploymentAddition: fb.control(null),
        applicationDate: fb.control(null, [Validators.required]),
        responsiblePerson: fb.control(null, [Validators.required]),
        status: fb.control({value: null, disabled: true}, [Validators.required]),
        isTemplate: fb.control(false),
        contactDetail: fb.control(false),
        language: fb.control(null),
        notificationEnabled: fb.control(false),
        hiddenFromUsers: fb.control(null),
        triggers: fb.array([]),
        excludedJobTriggersHiringStatusIds: fb.control([]),
        linkedinProfile: fb.control(null),
        allowAnonymousFlow: fb.control(null),
        activationDateTime: fb.control(null)
      },
      { validators: [correctActivDate, correctActivationDateTime] }
    ),
    jobDetails: fb.group({
      video: fb.control(null, [Validators.required]),
      picture: fb.control(null, [Validators.required]),
      jobDescription: fb.control(null, [
        Validators.required,
        blankCharacterValidation,
      ]),
      jobDescriptionTemplate: fb.control(null, Validators.required),
      shortJobDescription: fb.control(null, [
        Validators.required,
        blankCharacterValidation,
      ]),
      shortJobDescriptionTemplate: fb.control(null, [Validators.required]),
      reminderSms: fb.control('', [
        Validators.required,
        Validators.maxLength(maxReminderSmsLength),
        Validators.pattern(smsValidationPattern),
      ]),
      introMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(maxIntroMessageLength),
      ]),
      introMainMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(maxIntroMainMessageLength),
      ]),
      thankYouMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      thankYouMainMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(300)
      ]),
      sendReminderSms: fb.control(true),
    }),
    jobPublish: fb.group({
      jobPlatforms: fb.control([]),
      publishedJobPlatforms: fb.control([]),
      publishOnHigher: fb.control(false),
    }),
    quizModules: fb.group({
      orderOfQuestionsModules: fb.control([], [quizModulesValidator]),
      videoQuestions: fb.group({
        videoMessage: fb.control(null, [Validators.required, Validators.maxLength(maxVideoMessageLength)]),
        videoMainMessage: fb.control(null, [Validators.required, Validators.maxLength(maxVideoMainMessageLength)]),
        video: fb.control(null),
        questions: fb.array([
          fb.control(null, [Validators.required])
        ]),
      }),
      inhouseQuestion: createInhouseQuestionForm(),
      knockoutQuestions: createKnockoutQuestionsForm(),
      businessQuestions: createBusinessQuestionsForm(),
      personalityTest: fb.group({
        psykometrikaLanguage: fb.control(null, [Validators.required]),
      }),
    }),
  });
}

export function createBusinessQuestionsForm(): FormGroup {
  return fb.group({
    rejectionMessageMain: ['', [Validators.required]],
    rejectionMessage: ['', [Validators.required]],
    minPercentageForPass: 0,
    questions: fb.array([
      createBusinessQuestion()
    ]),
  });
}

export function createBusinessQuestion(isFollowing?: boolean): FormGroup {
  const questionForm: FormGroup = fb.group(
    {
      text: fb.control(null),
      image: fb.control(null),
      video: fb.control(null),
      questionType: fb.control(isFollowing ?
          TypeOfSkillsQuestion.questionWithFollowUpQuestions :
          TypeOfSkillsQuestion.singleAnswerQuestion),
      questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose: fb.control(null),
      maxNumberOfAnswersCandidateCanChoose: fb.control(1, [Validators.required]),
      answers: fb.array([
        fb.group({
          text: fb.control(null, [Validators.required]),
          desirability: fb.control(null),
          tag: fb.control(null, [Validators.maxLength(tagMaxLength)])
        }),
      ]),
    },
    {
      validators: [
        answerDesirabilityValidator,
        answerLengthValidator,
        correctAnswerValidator,
        imageOrQuestionValidator,
        correctAnswerValidator,
        maxNumberOfAnswersCandidateCanChooseValidator
      ]
    }
  );

  if (!isFollowing) {
    questionForm.addControl('questionImportance', fb.control(2, [Validators.required]));
  }
  return questionForm;
}

export function setBusinessQuestions(question: Question, isFollowing?: boolean): FormGroup {
  const questionFormGroup: FormGroup = fb.group(
    {
      text: fb.control(question.text),
      image: fb.control(question.image),
      video: fb.control(question.video),
      answers: fb.array([]),
      questionType: fb.control(question.questionType ? question.questionType : TypeOfSkillsQuestion.singleAnswerQuestion),
      questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose:
        fb.control(question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose),
      maxNumberOfAnswersCandidateCanChoose:
        fb.control( question.questionType === TypeOfSkillsQuestion.multipleAnswersQuestion ?
                      question.maxNumberOfAnswersCandidateCanChoose : 1),
    },
    {
      validators: [
        answerDesirabilityValidator,
        answerLengthValidator,
        correctAnswerValidator,
        imageOrQuestionValidator,
        correctAnswerValidator,
        maxNumberOfAnswersCandidateCanChooseValidator
      ]
    }
  );

  if (!isFollowing) {
    questionFormGroup.addControl('questionImportance', fb.control(question.questionImportance, [Validators.required]));
  }

  question.answers.forEach((answer, answerIndex) => {
    const answers = questionFormGroup.get('answers') as FormArray;
    const answerControl: FormGroup = fb.group({
      text: fb.control(answer.text, [Validators.required]),
      desirability: fb.control(answer.desirability),
      tag: fb.control(answer.tag, [Validators.maxLength(tagMaxLength)])
    });

    if (answer.followUpQuestion) {
      answerControl.addControl('followUpQuestion', setBusinessQuestions(answer.followUpQuestion, true));
    }

    answers.insert(answerIndex, answerControl);
  });

  return questionFormGroup;
}

export function createKnockoutQuestionsForm(): FormGroup {
  return fb.group({
    rejectionMessageMain: ['', [Validators.required]],
    rejectionMessage: ['', [Validators.required]],
    passAllCandidates: false,
    questions: fb.array([
      createKnockoutOrInhouseQuestion()
    ]),
  });
}

export function createKnockoutOrInhouseQuestion(): FormGroup {
  return fb.group(
    {
      text: [null, [Validators.required]],
      answers: fb.array([
        fb.group({
          text: [null, [Validators.required]],
          correct: false,
        }),
      ])
    },
    { validators: [correctAnswerValidator, answerLengthValidator] }
  );
}

export function setKnockoutOrInhouseQuestion(question: Question): FormGroup {
  const questionFormGroup: FormGroup = fb.group(
    {
      text: fb.control(question.text, [Validators.required]),
      answers: fb.array([])
    },
    { validators: [correctAnswerValidator, answerLengthValidator] }
  );

  question.answers.forEach((answer: Answer, answerIndex: number) => {
    const answers = questionFormGroup.get('answers') as FormArray;
    const answerControl = fb.group({
      text: fb.control(answer.text, [Validators.required]),
      correct: fb.control(answer.correct),
    });

    answers.insert(answerIndex, answerControl);
  });

  return questionFormGroup;
}

export function createInhouseQuestionForm(): FormGroup {
  return  fb.group({
    passAllInhouseCandidates: false,
    question: createKnockoutOrInhouseQuestion(),
  });
}

export function setInhouseQuiz(form: FormGroup, inhouseQuiz: InhouseQuiz, jobLanguage: TranslationKeys, setupService: SetupService): void {
  let quiz: InhouseQuiz = inhouseQuiz;

  if (!quiz) {
    const { text, text2, yes, no } = INHOUSE_QUESTION_CONTENT[jobLanguage];

    const question: Question = {
      text: text2 ? `${text} ${setupService.currentCompany.name} ${text2}?` : `${text} ${setupService.currentCompany.name}?`,
      answers: [
        {
          text: yes,
          correct: true
        },
        {
          text: no,
          correct: false
        }
      ]
    };

    quiz = {
      question,
      passAllInhouseCandidates: false
    };
  }

  const isDisabled = form.disabled;

  form.get('passAllInhouseCandidates').setValue(quiz.passAllInhouseCandidates);
  form.setControl('question', setKnockoutOrInhouseQuestion(quiz.question));

  if (isDisabled) {
    form.disable();
  }
}

export function setQuiz(form: FormGroup, quiz: Quiz): void {
  const questions = form.get('questions') as FormArray;
  questions.clear();

  form.get('rejectionMessage').setValue(quiz.rejectionMessage);
  form.get('rejectionMessageMain').setValue(quiz.rejectionMessageMain);

  if (quiz.hasOwnProperty('passAllCandidates')) {
    form.get('passAllCandidates').setValue(quiz.passAllCandidates);
  }

  if (quiz.hasOwnProperty('minPercentageForPass')) {
    form.get('minPercentageForPass').setValue(quiz.minPercentageForPass);
  }

  const isDisabled = form.disabled;

  quiz.questions
    .forEach((question: Question) => {
      const questionFormGroup = quiz.hasOwnProperty('passAllCandidates')
                                  ? setKnockoutOrInhouseQuestion(question)
                                  : setBusinessQuestions(question);

      questions.push(questionFormGroup);
    });

  if (isDisabled) {
    form.disable();
  }
}

export function setVideoQuestions(form: FormGroup, videoQuestion: VideoQuestion): void {
  if (videoQuestion) {
    form.patchValue(videoQuestion);

    const questionsFormArray = form.get('questions') as FormArray;
    questionsFormArray.clear();

    videoQuestion.questions
      .forEach((question: string) => {
        const control = fb.control(question || null, [Validators.required]);
        if (questionsFormArray.disabled) {
          control.disable();
        }
        questionsFormArray.push(control);
      });
  }
}

export function disableFormFields(form: FormGroup, fieldNames: string[]): void {
  fieldNames.forEach((fieldName: string) => {
    const formControl = form.get(fieldName);

    if (formControl) {
      formControl.disable();
      if (fieldName !== 'orderOfQuestionsModules' &&
          fieldName !== 'experienceRequired' ) {
        formControl.reset();
      }
    }
  });
}

export function enableFormFields(form: FormGroup, fieldNames: string[]): void {
  fieldNames.forEach((fieldName: string) => {
    const formControl = form.get(fieldName);

    if (formControl) {
      formControl.enable();
    }
  });
}

export function disableInactiveModules(quizModulesFormGroup: FormGroup): void {
  const activeModules = quizModulesFormGroup.get('orderOfQuestionsModules').value;

  QUIZ_MODULES_ARRAY
    .forEach((moduleName: string) => {
      const moduleForm = quizModulesFormGroup.get(moduleName);

      if (!activeModules.find((activeModule: string) => activeModule === moduleName)) {
        moduleForm.disable();
      } else {
        moduleForm.markAllAsTouched();
      }
    });
}

export function createJobTriggerForm(): FormGroup {
  return fb.group({
    applicationHiringStatus: fb.group({
      name: '',
      id: null
    }),
    actionType: [null],
    actionTimeType: [null],
    smsContent: fb.group({
      useUrl: [false],
      content: ['']
    }),
    emailContent: fb.group({
      attachments: null,
      useUrl: false,
      subject: [''],
      content: [''],
      greeting: ['']
    }),
    relativeActionTime: fb.group({
      day: [0],
      hour: [0]
    }),
    actionTimeOfWeek: fb.group({
      dayOfWeek: ['mon'],
      hour: [0],
      minute: [0],
      timeZone: [null]
    }),
    exportedTilTidEmployeeSetting: fb.group({
      tilTidCustomerId: [null],
      tilTidOrganizationUnitId: [null]
    }),
    nextHiringStatus: [null],
    nextHiringStatusDelayInMinutes: [null],
    refappConfiguration: [null],
    alvaLabsData: fb.group({
      newHiringStatusId: [null],
      requireLogicTest: true,
      requirePersonalityTest: true,
      scoreLimit: [null],
      scoreThreshold: [''],
      testProfileId: ['']
    })
  });
}

export function setJobTriggerFormArray(triggers: Trigger[]): FormArray {
  const formArray = fb.array<FormGroup>([]);

  triggers
    .forEach((trigger: Trigger) => {
      const form = createJobTriggerForm();
      form.patchValue(trigger);
      formArray.push(form);
    });

  return formArray;
}

export function initializeHigherJobForm(): FormGroup {
  return fb.group({
    jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    jobCategory: fb.control(null, [Validators.required]),
    branch: fb.control(null, [Validators.required]),
    jobLocation: fb.control(null, [Validators.required]),
    jobWorkLocation: fb.control(null),
    typeOfEmployment: fb.control(null, [Validators.required]),
    typeOfEmploymentAddition: fb.control(null),
    applicationDate: fb.control(null, [Validators.required]),
    responsiblePerson: fb.control({ value: null, disabled: true }, [Validators.required]),
    hiddenFromUsers: fb.control(null),
  });
}

export function initializeAmsJobForm(): FormGroup {
  return fb.group({
    amsTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    amsCategory: fb.control(null, [Validators.required]),
    amsOccupation: fb.control(null, [Validators.required]), // requires category
    amsMunicipality: fb.control(null),
    amsTimeExtent: fb.control(null, [Validators.required]),
    amsDuration: fb.control(null, [Validators.required]),
    amsSalary: fb.control(null, [Validators.required]),
    experienceRequired: fb.control(false, [Validators.required]),
    amsDrivingLicences: fb.control([]),
    qualificationsSummary: fb.control(null),
    preferredQualifications: fb.control(null),
    candidateMustOwnCar: fb.control(false, [Validators.required]),
    amsContinent: fb.control(null, [Validators.required]),
    amsJobLocationCountry: fb.control(null, [Validators.required]) // requires continent
  });
}

export function initializeBlocketJobForm(): FormGroup {
  return fb.group({
    blocketTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    blocketCategory: fb.control(null, [Validators.required]),
    blocketSubcategory: fb.control(null, [Validators.required]), // requires subcategory
    blocketRegion: fb.control(null, [Validators.required]),
    blocketMunicipality: fb.control(null, [Validators.required]), // requires region
    blocketTimeExtent: fb.control(null, [Validators.required]),
    blocketTypeOfEmployment: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)])
  });
}

export function initializeFinnJobForm(): FormGroup {
  return fb.group({
    finnTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    norwegianZipCode: fb.control(null, [Validators.required]),
    finnSector: fb.control(null, [Validators.required]),
    finnDuration: fb.control(null, [Validators.required]),
    finnOccupationsGeneral: fb.control(null, [Validators.required]),
    finnOccupationSpecializations: fb.control([], [Validators.maxLength(finnMaxNumberOfSpecialization), Validators.required]),
    finnIndustries: fb.control([], [Validators.maxLength(finnMaxNumberOfIndustries), Validators.required]),
    finnTimeExtent: fb.control(null, [Validators.required]),
    finnWorkingLanguages: fb.control([], [Validators.maxLength(finnMaxNumberOfLanguages)]),
    finnRemoteOptions: fb.control([], [Validators.maxLength(finnMaxNumberOfRemotes)]),
    costCenter: fb.control(null, [Validators.required, Validators.maxLength(30)])
  });
}

export function initializeIndeedJobForm(): FormGroup {
  return fb.group({
    indeedJobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    indeedCategory: fb.control(null, [Validators.required]),
    indeedLocation: fb.control(null, [Validators.required]),
    indeedTypeOfEmployment: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)])
  });
}

export function initializeNavJobForm(): FormGroup {
  return fb.group({
    navTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    navCategory: fb.control(null),
    norwegianZipCode: fb.control(null, [Validators.required]),
    navEngagementType: fb.control(null),
    navExtent: fb.control(null),
    navJobArrangement: fb.control(null),
    navRemote: fb.control(null),
    navSector: fb.control(null),
    navWorkDay: fb.control(null),
    navWorkHours: fb.control(null),
    positions: fb.control(1, [Validators.required, Validators.min(1)]),
    navOccupations: fb.control(null),
    navLocation: fb.control(null, [Validators.required]),
    navCategories: fb.control('', [Validators.required])
  });
}

export function initializeJobindexJobForm(): FormGroup {
  return fb.group({
    jobindexTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    jobindexCategory: fb.control(null, [Validators.required]),
    jobindexLocation: fb.control(null, [Validators.required]),
    jobindexTypeOfEmployment: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)])
  });
}

export function initializeDuunitoriJobForm(): FormGroup {
  return fb.group({
    duunitoriTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    duunitoriCategory: fb.control(null, [Validators.required]),
    duunitoriLocation: fb.control(null, [Validators.required]),
    duunitoriTypeOfEmployment: fb.control(null, [Validators.required]),
    duunitoriBannerImage: fb.control('', [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)])
  });
}

export function initializeFacebookAndInstagramJobForm(): FormGroup {
  return fb.group({
    jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    facebookAndInstagramJobPitch: fb.control(null, [Validators.required]),
    primaryLocation: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    jobCategory: fb.control(null, [Validators.required]),
    secondaryLocation: fb.control(null),
    video: fb.control(null, [Validators.required]),
    image: fb.control(null, [Validators.required]),
    storyVideo: fb.control(null),
    storyImage: fb.control(null),
    flexibleRemoteJob: fb.control(null),
    remoteJob: fb.control(null),
    partTimeJob: fb.control(null),
    temporaryJob: fb.control(null),
    selectedPricePackage: fb.control(null, [Validators.required]),
  });
}

export function initializeBesokslivjobbJobForm(): FormGroup {
  return fb.group({
    besokslivTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    shortJobDescription: fb.control(null, [Validators.required]),
    besokslivLocation: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    besokslivPackage: fb.control(null, [Validators.required])
  });
}

export function initializeVerkstadsjobbJobForm(): FormGroup {
  return fb.group({
    verkstadsjobbTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    verkstadsjobbCategory: fb.control(null, [Validators.required]),
    verkstadsjobbLocation: fb.control(null, [Validators.required]),
    verkstadsjobbBrands: fb.control(null, [Validators.required]),
  });
}

export function initializeAdwayJobForm(): FormGroup {
  return fb.group({
    adwayTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    adwayCategory: fb.control(null, [Validators.required]),
    shortJobDescription: fb.control(null, [Validators.required]),
    adwayLocation: fb.control(null, [Validators.required]),
    adwayLocationAlternative: fb.control(null),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    video: fb.control(null, [Validators.required]),
    picture: fb.control(null, [Validators.required]),
    storyVideo: fb.control(null),
    storyPicture: fb.control(null),
    isFlexibleRemote: fb.control(null),
    isRemote: fb.control(null),
    isPartTime: fb.control(null),
    isTemporary: fb.control(null),
    boost: fb.group({
      id: fb.control(null),
      name: fb.control(null),
      price: fb.control(null),
      currency: fb.control(null),
  })
});
}

export function initializeJobnetJobForm(): FormGroup {
  return fb.group({
    title: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    jobnetDriverLicenseType: fb.control(null),
    jobnetJobAdType: fb.control(null, [Validators.required]),
    jobnetOccupation: fb.control(null, [Validators.required]),
    jobnetJobAdPropertyType: fb.control(null, [Validators.required]),
    jobnetEmploymentType: fb.control(null, [Validators.required]),
    jobnetWeeklyWorkTimeType: fb.control(null, [Validators.required]),
    phoneNumber: fb.control(null, [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
    pNumber: fb.control(null, [Validators.pattern('^[0-9]{10}$'), Validators.required]),
    numberOfVacancies: fb.control(null, [Validators.required, Validators.max(MAX_NUMBER_OF_VACANCIES)]),
    noFixedWorkplace: fb.control(false, [Validators.required]),
    handicapNoObstacle: fb.control(false, [Validators.required]),
    worktimeType: fb.group({
      normalWork: fb.control(false, [Validators.required]),
      changingWorkingHours: fb.control(false, [Validators.required]),
      morningWork: fb.control(false, [Validators.required]),
      eveningWork: fb.control(false, [Validators.required]),
      nightWork: fb.control(false, [Validators.required]),
      weekendWork: fb.control(false, [Validators.required]),
      highestWeeklyWorkingHours: fb.control(null,
        [Validators.max(MAX_NUMBER_OF_WEEKLY_WORKING_HOURS), Validators.min(MIN_NUMBER_OF_WEEKLY_WORKING_HOURS)]),
      lowestWeeklyWorkingHours: fb.control(null,
        [Validators.max(MAX_NUMBER_OF_WEEKLY_WORKING_HOURS), Validators.min(MIN_NUMBER_OF_WEEKLY_WORKING_HOURS)])
    }
    , {validators: compareHighestAndLowestWeeklyWorkingHoursValidator}
    ),
    jobnetMunicipality: fb.control(null, [Validators.required]),
    workPlaceAddress: fb.group({
      postalDistrict: fb.control(null, [Validators.required]),
      postalNumber: fb.control(null,
        [Validators.required, Validators.minLength(POSTAL_NUMBER_LENGTH), Validators.maxLength(POSTAL_NUMBER_LENGTH)]),
      houseNumber: fb.control(null, [Validators.required]),
      street: fb.control(null, [Validators.required, checkIsStreetIsNumber])
    })
  });
}

export function initializeTikTokJobForm(): FormGroup {
  return fb.group({
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    selectedPricePackage: fb.control(null, [Validators.required]),
    tiktokTitle: fb.control({value: null, disabled: true}, [Validators.required, Validators.maxLength(75)]),
    storyVideo: fb.control(null, [Validators.required]),
    storyImage: fb.control(null, [Validators.required])
  });
}

export function initializeLinkedInJobForm(): FormGroup {
  return fb.group({
    jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    jobCategory: fb.control(null, [Validators.required]),
    linkedInJobPitch: fb.control(null, [Validators.required]),
    location: fb.control(null, [Validators.required]),
    video: fb.control(null, [Validators.required]),
    image: fb.control(null, [Validators.required]),
  });
}

export function initializeHigherChargeableJobForm(): FormGroup {
  return fb.group({
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)]),
    jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    video: fb.control(null, [Validators.required]),
    image: fb.control(null, [Validators.required]),
    higherChannelsJobPitch: fb.control(null, [Validators.required])
  });
}

export enum JobWorkLocationEnum {
  office = 1,
  remoteFlexibility = 2,
  remote = 3,
  temporaryRemote = 4
}

export enum JobTypesOfEmploymentEnum {
  fullTime = 5,
  partTime = 6
}
