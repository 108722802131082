import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, forkJoin, Subject } from 'rxjs';
import { map, tap, switchMap, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SetupService } from './setup.service';
import { Pagination } from '../model/pagination.interface';
import { defineLocale, deLocale, daLocale, fiLocale, svLocale, nbLocale } from 'ngx-bootstrap/chronos';
import { Roles } from '../model/role.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { TranslationKeys } from '../model/translation-object.interface';
import { CompanyService } from './company.service';
import { CompanyUserPayload, ResponsiblePerson, UpgradeUserPayload, User, UserPayload, UserProfile } from '../model/user.interface';

defineLocale('da', daLocale);
defineLocale('de', deLocale);
defineLocale('fi', fiLocale);
defineLocale('sv', svLocale);
defineLocale('no', nbLocale);

@Injectable({ providedIn: 'root' })
export class UserService {

  private _refreshedCompanyUsers$: Subject<User[]> = new Subject<User[]>();
  private _refreshedAllCompanyUsers$: Subject<User[]> = new Subject<User[]>();
  private _refreshedEnterpriseUsers$: Subject<User[]> = new Subject<User[]>();
  private _currentUser: User;
  private _renderer: Renderer2;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private setupService: SetupService,
    private rendererFactory: RendererFactory2,
    private companyService: CompanyService
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  get refreshedCompanyUsers$(): Observable<User[]> {
    return this._refreshedCompanyUsers$.asObservable();
  }

  get refreshedAllCompanyUsers$(): Observable<User[]> {
    return this._refreshedAllCompanyUsers$.asObservable();
  }

  get refreshedEnterpriseUsers$(): Observable<User[]> {
    return this._refreshedEnterpriseUsers$.asObservable();
  }

  get user(): User {
    return this._currentUser;
  }

  get role(): Roles {
    return this._currentUser.role;
  }

  get userId(): number {
    return this._currentUser.id;
  }

  createUser(
    user: UserPayload,
    organization: string,
    searchQuery: string
  ): Observable<User[]> {
    return this.http.post(environment[`${organization}Users`], user)
      .pipe(
        switchMap(() => this.getUpdatedUsers(organization, searchQuery))
      );
  }

  getCurrentUser(): Observable<User> {
    return this.http.get(environment.self)
      .pipe(
        map(({data}: Pagination<User>) => data[0]),
        map((user: User) => {
          if (user.roles[0] !== Roles.admin && user.roles[0] !== Roles.adminSales) {
            this.setCurrentUser(user);
          }

          return user;
        })
      );
  }

  getEnterpriseUsers(searchByName?: string, haveAccessToCompany?: number): Observable<User[]> {
    let params = new HttpParams();

    params = params.append('limit', '1000');

    if (searchByName) {
      params = params.append('searchBy', searchByName);
    }

    if (haveAccessToCompany) {
      params = params.append('haveAccessToCompany', haveAccessToCompany);
    }

    return this.http
      .get(`${environment.enterpriseUsers}`, { params })
      .pipe(
        map(({data}: Pagination<User>) => data)
      );
  }

  getCompanyUsers(companyGuid: string, searchByName?: string, forRequisitionFormContext?: boolean ): Observable<User[]> {
    let params = new HttpParams();

    params = params.append('limit', '1000');

    if (searchByName) {
      params = params.append('searchBy', searchByName);
    }

    if (forRequisitionFormContext) {
      params = params.append('collectionType', 'forRequisitionFormContext');
    }

    return this.http
      .get(`${environment.companies}/${companyGuid}/customers`, { params })
      .pipe(
        map(({data}: Pagination<User>) => data)
      );
  }

  getCompanyUsersByBranchId(branchIds: number[]): Observable<User[]> {
    let params = new HttpParams();

    params = params.append('limit', '1000');

    params = branchIds.length
      ? params.append('branches', branchIds.join(','))
      : params.append('allowedUniversalJob', 'true');

    return this.http
      .get( `${environment.companies}/${this.setupService.companyGuid}/customers`, { params })
      .pipe(
        map(({data}: Pagination<User>) => data)
      );
  }

  getUpdatedUsers(organization: string, searchByName?: string): Observable<User[]> {
    let endpoint: string;
    let users$: Subject<User[]>;
    let allUsers$: Subject<User[]>;

    if (organization === 'company') {
      endpoint = `${environment.companies}/${this.setupService.companyGuid}/customers`;
      users$ = this._refreshedCompanyUsers$;
      allUsers$ = this._refreshedAllCompanyUsers$;
    } else {
      endpoint = environment.enterpriseUsers;
      users$ = this._refreshedEnterpriseUsers$;
      allUsers$ = this._refreshedEnterpriseUsers$;
    }

    let params = new HttpParams();

    params = params.append('limit', '1000');

    if (searchByName) {
      return this.http
        .get(`${endpoint}`, { params })
        .pipe(
          map(({data}: Pagination<User>) => data),
          tap((users: User[]) => allUsers$.next(users)),
          switchMap(() => {
            params = params.append('searchBy', searchByName);

            return this.http
              .get(`${endpoint}`, { params })
              .pipe(
                map(({data}: Pagination<User>) => data),
                tap((users: User[]) => users$.next(users))
              );
          })
        );
    }

    return this.http
      .get(`${endpoint}`, { params })
      .pipe(
        map(({data}: Pagination<User>) => data),
        tap((users: User[]) => {
          allUsers$.next(users);
          users$.next(users);
        })
      );
  }

  upgradeUserRole(
    userId: number,
    upgradePayload: UpgradeUserPayload,
    companyGuid: string,
    searchQueryEnterprise: string,
    searchQueryCompany: string
  ): Observable<User[][]> {
    return this.http.put<User[]>(`${environment.companyUsers}/${userId}/upgrade_to_enterprise_manager`, upgradePayload)
      .pipe(
        switchMap(() => {
          return this.getUpdatedUsersAfterRoleUpgrade(companyGuid, searchQueryCompany, searchQueryEnterprise);
        })
      );
  }

  getUpdatedUsersAfterRoleUpgrade(companyGuid: string, searchQueryCompany: string, searchQueryEnterprise: string): Observable<User[][]> {
    return forkJoin([
      this.getEnterpriseUsers(searchQueryEnterprise),
      this.getCompanyUsers(companyGuid, searchQueryCompany)
    ]).pipe(
      tap(([enterpriseUsers, companyUsers]: User[][]) => {
        this._refreshedEnterpriseUsers$.next(enterpriseUsers);
        this._refreshedAllCompanyUsers$.next(companyUsers);
      })
    );
  }

  editCurrentUser(userData: UserProfile, organization: string): Observable<User> {
    return this.http.put(`${environment[`${organization}Users`]}/${this.userId}`, userData)
      .pipe(
        switchMap(() => {
          return this.getCurrentUser();
        })
      );
  }

  removeCurrentUser(): void {
    this._currentUser = null;
  }

  editUser(
    id: number,
    user: CompanyUserPayload,
    organization: string,
    searchQuery?: string,
    searchBranchByName?: string
  ): Observable<User[]> {
    return this.http.put(`${environment[`${organization}Users`]}/${id}`, user)
      .pipe(
        switchMap(() => {
          if (organization === 'enterprise') {
            return of (null);
          } else {
            return this.companyService.getUpdatedCompanyBranches(searchBranchByName);
          }
        }),
        switchMap(() => this.getUpdatedUsers(organization, searchQuery)),
      );
  }

  removeUser(
    userId: number,
    responsiblePersonForm: ResponsiblePerson,
    organization: string,
    searchQuery?: string
  ): Observable<User[]> {
    const responsiblePerson = responsiblePersonForm.responsiblePerson;
    const isCompanyHR = responsiblePerson.roles[0] === Roles.companyHR;
    const isEnterpriseManager = ENTERPRISE_ROLES.includes(responsiblePerson.roles[0]);
    const payload =
      {
        headers: {},
        body: {
          responsibleCustomer: isCompanyHR ? responsiblePerson.id : null,
          responsibleEnterpriseManager: isEnterpriseManager ? responsiblePerson.id : null,
          videoInterviewsAndNewEmployees: responsiblePersonForm.videoInterviewsAndNewEmployees,
          groupVideoInterviewsAndNewEmployees: responsiblePersonForm.groupVideoInterviewsAndNewEmployees
        }
      };

    return this.http
      .delete<string>(`${environment[`${organization}Users`]}/${userId}`, payload)
      .pipe(
        switchMap(() => this.getUpdatedUsers(organization, searchQuery))
      );
  }

  removeBranch(userId: number, branchId: number, searchQuery?: string): Observable<User[]> {
    return this.http
      .delete(`${environment.companyUsers}/${userId}/branches/${branchId}`)
      .pipe(
        switchMap(() => this.getUpdatedUsers('company', searchQuery))
      );
  }

  changePassword(oldPassword: string, newPassword: string, organization: string): Observable<User> {
    return this.http.put<User>(
      `${environment[`${organization}Users`]}/${this.userId}`,
      {
        oldPassword,
        plainPassword: newPassword,
      }
    );
  }

  setCurrentUser(user: User): void {
    this.translateService.use(
      user.preferredLanguage ||
      user.company?.customerPortalLanguage ||
      user.company?.language ||
      TranslationKeys.EN
    );

    user.role = user.roles[0];
    this._currentUser = user;
  }

  getResponsiblePersonsByBranch(branchIds: number[]): Observable<User[]> {
    return this.getCompanyUsersByBranchId(branchIds)
      .pipe(
        mergeMap((users: User[]) => {
          if (this.setupService.currentCompany.enterprise) {
            return this.getEnterpriseUsersConnectedToCompany()
              .pipe(
                map((managers: User[]) => {
                  return [...users, ...managers];
                })
              );
          }

          return of(users);
        })
      );
  }

  getEnterpriseUsersConnectedToCompany(): Observable<User[]> {
    return this.getEnterpriseUsers('', this.setupService.companyId);
  }

  getAnonymousUser(companyId: string): Observable<{guid: string}> {
    return this.http
      .get<{guid: string}>(`${environment.companies}/${companyId}/anonymous_customer`);
  }
}
